<template>
  <div :class="classMessageType" :id="id">
    <div :class="classWrapper">
      <div v-if="showUnreadMark" class="message__indicator">
        <div class="message__indicator-icon"></div>
      </div>
      <div v-if="!isIncoming && showHeader" class="message__header">
        <div class="message__author">{{ authorName }}</div>
      </div>
      <slot></slot>
    </div>
    <span v-if="showFooter" :title="formattedTimestamp" class="message__footer">
      <div>
        {{ timestamp }}
        <message-footer-icon
          v-if="!isIncoming"
          :errorCode="this.message.errorCode"
          :typeUser="this.message.typeUser"
          :created-at="this.message.createdAt"
          :delivered-at="this.message.deliveredAt"
          :read-at="this.message.readAt"
        />
      </div>
      <div v-if="hasError" class="message__footer-error">
        {{ errorMessage }}
      </div>
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Codes from '@/utils/errorCodes';

export default {
  components: {
    MessageFooterIcon: () => import('@/components/chat/message/MessageFooterIcon.vue'),
  },
  props: {
    message: {
      required: true,
      type: Object,
    },
    hasNext: {
      default: false,
    },
    sameMinute: {
      default: false,
    },
    id: {
      type: String,
    },
  },
  computed: {
    ...mapGetters('shared', [
      'userInfo',
    ]),
    isDeleted() {
      return this.message.errorCode === '9999';
    },
    classMessageType() {
      return [
        'message',
        {
          'message--in': this.isIncoming,
          'message--out': !this.isIncoming,
          'message--sys': this.isAutomatic,
          'message--has-next': this.hasNext,
        },
      ];
    },
    isIncoming() {
      return this.typeUser === 'Client';
    },
    isAutomatic() {
      return this.typeUser === 'UserSystem';
    },
    typeUser() {
      return this.message.typeUser;
    },
    user() {
      return this.message.user;
    },
    authorName() {
      return this.user ? this.user.name : '';
    },
    timestamp() {
      if (this.message.createdAt) {
        return this.globalFormatTime(this.message.createdAt);
      }

      return '';
    },
    formattedTimestamp() {
      if (this.message.createdAt) {
        return this.globalFormatDatetime(this.message.createdAt);
      }

      return '';
    },
    conversationData() {
      return this.message.conversation ?? { agent: null, client: null };
    },
    agentId() {
      return this.conversationData.agent ?? '';
    },
    isTemplate() {
      return this.message.type === 'tText';
    },
    classWrapper() {
      return {
        message__template: this.showBubble && this.isTemplate && !this.hasError,
        message__bubble: this.showBubble,
        'message__bubble-error': this.showBubble && this.hasError,
        message__default: !this.showBubble,
        message__deleted: this.isDeleted,
      };
    },
    showFooter() {
      return !this.sameMinute || !this.message.createdAt;
    },
    showBubble() {
      return ['text', 'image', 'video', 'document', 'location', 'tText'].includes(this.message.type);
    },
    showUnreadMark() {
      return this.isIncoming && this.message.readAt === null;
    },
    showHeader() {
      return this.agentId !== this.userInfo.id;
    },
    hasError() {
      return this.message.errorCode !== null && this.message.typeUser === 'User';
    },
    errorMessage() {
      const error = Codes.find(({ code }) => code === Number(this.message.errorCode));
      if (error && !this.message.errorReason) {
        return error.message;
      }

      return this.message.errorReason;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/chat/_message.scss";
</style>
